import {
  createEffect,
  createSignal,
  JSX,
  Match,
  onMount,
  onCleanup,
  Show,
  Switch,
  For,
} from 'solid-js'
import { AudioLoader, resumeAudioOnClick } from './utils'

const fadeOpus = new URL('audio/fade.opus', import.meta.url)
const fadeCaf = new URL('audio/fade.caf', import.meta.url) // Safari

function Modal(props: {
  children: JSX.Element
  closable?: boolean
  title: string
  onClose: () => void
}) {
  return (
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{props.title}</h5>
        <Show when={props.closable}>
          <button type="button" class="close" onClick={() => props.onClose()}>
            <span aria-hidden="true">&times;</span>
          </button>
        </Show>
      </div>
      <div class="modal-body">{props.children}</div>
    </div>
  )
}

function BirdCountModal(props: { onClose: (newModal?: string) => void }) {
  const [count, setCount] = createSignal(0)

  const close = (newModal?: string) => {
    reset()
    props.onClose(newModal)
  }

  const increment = () => {
    setCount(c => c + 1)
  }

  const decrement = () => {
    setCount(c => c - 1)
  }

  const reset = () => {
    setCount(0)
  }

  const submit = () => {
    if (count() === 2) {
      window.location.href = 'https://www.youtube.com/watch?v=xga-nxXrizE'
    } else {
      close('not-bird')
    }
  }

  return (
    <>
      <Modal closable title="Bird..." onClose={close}>
        <h1>Number of birds: {count()}</h1>
        <div class="actions">
          <button onClick={increment}>+</button>
          <button onClick={decrement}>-</button>
          <button onClick={reset}>Reset</button>
          <button onClick={submit} class="primary">
            Submit
          </button>
        </div>
      </Modal>
    </>
  )
}

function NotBirdModal() {
  const buttons = [
    ['Okay', 'Yes', 'Understood'],
    ['Accept', 'Agree', 'Got it'],
    ['Okay', 'Not', 'Bird'],
    ['Not', 'a', 'Bird'],
    ['not', 'bird', 'no'],
    ['leave', 'it', 'alone'],
    ['stop', 'looking', 'at me'],
    ['get', 'out', 'of my head'],
    ['no', 'bird', 'no'],
    ['birds', 'are', 'not'],
    ['i', 'will', 'die'],
    ['bird', 'never', 'be'],
    ['time', 'is', 'up'],
    ['no', 'resist', 'not'],
    ['no', 'no', 'bird'],
    ['no', 'no', 'no'],
    ['no', 'liar', 'no'],
    ['liar', 'i am', 'liar'],
    ['i am a liar', 'i am', 'i am a liar'],
    ['i am a liar', 'i am a liar', 'i am a liar'],
  ]
  const headings = [
    'You are not a bird.',
    'You are not bird.',
    'You are not welcome.',
    'You should not be here.',
    'Leave.',
    'Liar.',
  ]
  const titles = [
    'Not a bird',
    'Not bird',
    'not bird',
    'horrid creature',
    'leave leave leave leave',
    'liar',
  ]
  const [index, setIndex] = createSignal(0)
  const [title, setTitle] = createSignal('Not a bird')
  const [heading, setHeading] = createSignal('You are not a bird.')
  const next = () => {
    if (index() < buttons.length - 1) {
      setIndex(i => i + 1)
    }
    const titleIndex = Math.min(Math.floor(index() / 3), titles.length - 1)
    if (titleIndex < titles.length) {
      setTitle(titles[titleIndex])
      setHeading(headings[titleIndex])
    }
  }

  return (
    <Modal title={title()} onClose={close}>
      <h1>{heading()}</h1>
      <div class="actions">
        <For each={buttons[index()]}>
          {text => <button onClick={next}>{text}</button>}
        </For>
      </div>
    </Modal>
  )
}

function ModalSwitcher(props: {
  modal: string
  setModal: (modal: string) => void
}) {
  const [active, setActive] = createSignal(false)
  createEffect(() => {
    setActive(Boolean(props.modal))
  })

  const close = (newModal?: string) => {
    props.setModal(newModal ?? '')
  }

  return (
    <Show when={active()}>
      <div class="modal">
        <Switch>
          <Match when={props.modal === 'bird-count'}>
            <BirdCountModal onClose={close} />
          </Match>
          <Match when={props.modal === 'not-bird'}>
            <NotBirdModal />
          </Match>
        </Switch>
      </div>
    </Show>
  )
}

function FadeLoop(props: {
  audioContext: AudioContext
  buffer: Promise<AudioBuffer> | AudioBuffer
}) {
  let node: AudioBufferSourceNode | undefined

  onMount(async () => {
    const audio = await props.buffer
    node = props.audioContext.createBufferSource()
    const gainNode = props.audioContext.createGain()

    node.loop = true
    node.buffer = audio
    gainNode.gain.value = 0
    node.connect(gainNode).connect(props.audioContext.destination)

    let frames = 0
    const update = () => {
      if (!node) {
        return
      }

      frames++

      if (frames > 10) {
        gainNode.gain.value = Math.min(gainNode.gain.value + 0.001, 1)
        frames = 0
      }

      if (gainNode.gain.value !== 1) {
        requestAnimationFrame(update)
      }
    }

    node.start()
    requestAnimationFrame(update)
  })

  onCleanup(() => {
    node?.stop()
    node = undefined
  })

  return <></>
}

export default function App() {
  const [modal, setModal] = createSignal('')
  const audioContext = new AudioContext()
  const loader = new AudioLoader(audioContext)
  const fade = loader.loadBuffer([fadeOpus, fadeCaf])

  onMount(() => {
    resumeAudioOnClick(audioContext)
  })

  return (
    <>
      <Show when={modal() === 'not-bird'}>
        <div class="something"></div>
        <FadeLoop audioContext={audioContext} buffer={fade} />
      </Show>
      <ModalSwitcher modal={modal()} setModal={setModal} />
      <div class="birds"></div>
      <header>Birds Only</header>
      <div class="actions">
        <button onClick={() => setModal('bird-count')}>Bird?</button>
        <button onClick={() => (window.location.href = 'birds')}>Bird.</button>
        <button onClick={() => (window.location.href = 'bird')}>Bird!</button>
      </div>
    </>
  )
}
